.header-color {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footer-color {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #000000,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #000000,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  bottom: 0;
  padding: 15px;
}

/* Smallest phones */
@media (max-width: 567px) and (max-height: 600px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }

  .social-links i {
    color: white;
    font-size: 1.5em;
  }

  /* Resume Page css */
  .resume-right-col {
    /* background: #27221f; */
    background: #152231;
    color: white;
    padding: 2em;
  }

  .about-photo {
    width: 40%;
  }

  .carousel-width {
    bottom: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .cImages {
    object-fit: contain;
    height: 500px;
  }
  /* UNLV logo in portfolio */
  .unlv {
    width: 140px;
    height: 140px;
  }
  /* h2 tags */
  .h2 {
    font-size: 9vw;
  }
  /* h3 tags */
  .h3 {
    font-size: 7.8vw;
  }
  /* tabs in portfolio */
  .tabs {
    width: 100px;
  }

  .capstone-tab {
    width: 100%;
  }

  .award-photo {
    width: 100%;
  }

  .frontend-cell {
    justify-content: space-between;
  }

  .biokey-logo {
    width: 66%;
  }

  .biokey-details {
    width: 100%;
    padding: 0.3em;
  }

  .frontend-logos {
    height: 52px;
    padding: 0.5em;
  }

  .frontend-logos-width {
    width: 160px;
    padding: 0.5em;
  }

  .biokey-app {
    height: 410px;
    padding: 0.2em;
  }

  .p {
    font-size: 4vw;
  }

  .h5 {
    font-size: 5vw;
  }

  .capstone-title {
    font-size: 4.8vw;
  }

  .cs-courses-tab {
    width: 100%;
  }
}

/* Medium sized devices */
@media (min-height: 601px) and (max-width: 567px) and (max-height: 700px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .social-links i {
    color: white;
    font-size: 1.5em;
  }

  /* Resume Page css */
  .resume-right-col {
    /* background: #27221f; */
    background: #152231;
    color: white;
    padding: 2em;
  }

  .about-photo {
    width: 38%;
  }

  .carousel-width {
    bottom: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .cImages {
    object-fit: contain;
    height: 500px;
  }
  /* UNLV logo in portfolio */
  .unlv {
    width: 170px;
    height: 170px;
  }
  /* h2 tags */
  .h2 {
    /* font-weight: bold; */
    font-size: 9vw;
  }
  /* h3 tags */
  .h3 {
    /* font-weight: bold; */
    font-size: 7.8vw;
  }
  /* tabs in portfolio */
  .tabs {
    width: 100px;
  }

  .capstone-tab {
    width: 100%;
  }

  .award-photo {
    width: 100%;
  }

  .frontend-cell {
    justify-content: space-between;
  }

  .biokey-logo {
    width: 65%;
  }

  .biokey-details {
    width: 100%;
    padding: 0.3em;
  }

  .frontend-logos {
    height: 58px;
    padding: 0.5em;
  }

  .frontend-logos-width {
    width: 175px;
    padding: 0.5em;
  }

  .biokey-app {
    height: 510px;
    padding: 0.2em;
  }

  .p {
    font-size: 4vw;
  }

  .h5 {
    font-size: 5.2vw;
  }

  .capstone-title {
    font-size: 5vw;
  }

  .cs-courses-tab {
    width: 100%;
  }
}

/* Large phones */
@media (min-height: 701px) and (max-width: 567px) and (max-height: 800px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .social-links i {
    color: white;
    font-size: 2em;
  }

  /* Resume Page css */
  .resume-right-col {
    /* background: #27221f; */
    background: #152231;
    color: white;
    padding: 2em;
  }

  .about-photo {
    width: 38%;
  }

  .carousel-width {
    bottom: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .cImages {
    object-fit: contain;
    height: 500px;
  }
  /* UNLV logo in portfolio */
  .unlv {
    width: 170px;
    height: 170px;
  }
  /* h2 tags */
  .h2 {
    /* font-weight: bold; */
    font-size: 8.5vw;
  }
  /* h3 tags */
  .h3 {
    /* font-weight: bold; */
    font-size: 6.5vw;
  }
  /* tabs in portfolio */
  .tabs {
    width: 100px;
  }

  .capstone-tab {
    width: 100%;
  }

  .award-photo {
    width: 100%;
  }

  .frontend-cell {
    justify-content: space-between;
  }

  .biokey-logo {
    width: 66%;
  }

  .biokey-details {
    width: 100%;
    padding: 0.3em;
  }

  .frontend-logos {
    height: 60px;
    padding: 0.5em;
  }

  .frontend-logos-width {
    width: 185px;
    padding: 0.5em;
  }

  .biokey-app {
    height: 545px;
    padding: 0.2em;
  }

  .p {
    font-size: 4.2vw;
  }

  .h5 {
    font-size: 5.4vw;
  }

  .capstone-title {
    font-size: 5.1vw;
  }

  .cs-courses-tab {
    width: 100%;
  }
}

/* Largest phones (XS-Max, Pixel 3, etc..) */
@media (min-height: 801px) and (max-width: 567px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }

  .social-links i {
    color: white;
    font-size: 2em;
  }

  .bottom {
    /* height: 5px; */
    background-color: black;
  }
  /* Resume Page css */
  .resume-right-col {
    /* background: #27221f; */
    background: #152231;
    color: white;
    padding: 2em;
  }

  .about-photo {
    width: 40%;
  }

  .carousel-width {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .cImages {
    object-fit: contain;
    height: 500px;
  }
  /* UNLV logo in portfolio */
  .unlv {
    width: 170px;
    height: 170px;
  }
  /* h2 tags */
  .h2 {
    font-size: 9vw;
  }
  /* h3 tags */
  .h3 {
    font-size: 8vw;
  }
  /* tabs in portfolio */
  .tabs {
    width: 100px;
  }

  .capstone-tab {
    width: 100%;
  }

  .award-photo {
    width: 100%;
  }

  .biokey-logo {
    width: 65%;
  }

  .biokey-details {
    width: 100%;
    padding: 0.3em;
  }

  .frontend-logos {
    height: 60px;
    padding: 0.5em;
  }

  .frontend-logos-width {
    width: 185px;
    padding: 0.5em;
  }

  .biokey-app {
    height: 510px;
    padding: 0.2em;
  }

  .p {
    font-size: 4.5vw;
  }

  .h5 {
    font-size: 5.8vw;
  }

  .capstone-title {
    font-size: 5.2vw;
  }

  .cs-courses-tab {
    width: 100%;
  }
}

/* Landscape mode on large phones */
@media (min-width: 567px) and (max-height: 499px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }

  .social-links i {
    color: white;
    font-size: 2em;
  }

  .bottom {
    background-color: black;
  }
  /* Resume Page css */
  .resume-right-col {
    /* background: #27221f; */
    background: #152231;
    color: white;
    padding: 2em;
  }

  .about-photo {
    width: 68%;
  }

  .carousel-width {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .cImages {
    object-fit: contain;
    height: 100%;
  }
  /* UNLV logo in portfolio */
  .unlv {
    width: 55%;
  }
  /* h2 tags */
  .h2 {
    font-size: 3vw;
  }
  /* h3 tags */
  .h3 {
    font-size: 3vw;
  }
  /* tabs in portfolio */
  .tabs {
    width: 100px;
  }

  .capstone-tab {
    width: 100%;
  }

  .award-photo {
    width: 100%;
  }

  .biokey-logo {
    width: 33%;
  }

  .biokey-details {
    width: 100%;
    padding: 0.3em;
  }

  .frontend-logos {
    height: 70px;
    padding: 0.5em;
  }

  .frontend-logos-width {
    width: 135px;
    padding: 0.5em;
  }

  .biokey-app {
    height: 510px;
    padding: 0.2em;
  }

  .p {
    font-size: 1.8vw;
  }

  .h5 {
    font-size: 3vw;
  }

  .h4 {
    font-size: 2.5vw;
  }

  .capstone-title {
    font-size: 2.5vw;
  }

  .cs-courses-tab {
    width: 100%;
  }
}

/* Larger than a phone */
@media (min-width: 567px) and (min-height: 500px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
  }

  .social-links i {
    color: white;
    font-size: 3em;
  }

  .bottom {
    background-color: black;
  }
  /* Resume Page css */
  .resume-right-col {
    /* background: #27221f; */
    /* background: #2b303a; */
    /* background: #333a47; */
    background: #152231;
    color: white;
    padding: 2em;
  }
  /* About me photo */
  .about-photo {
    width: 50%;
  }

  .carousel-width {
    width: 90%;
    bottom: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .unlv {
    width: 300px;
    height: 300px;
  }

  .h3 {
    font-size: 2.1vw;
  }
  /* h2 tag */
  .h2 {
    /* font-weight: bold; */
    font-size: 2.8vw;
  }

  .capstone-tab {
    width: 68%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .award-photo {
    width: 95%;
  }

  .biokey-logo {
    width: 31%;
  }

  .biokey-details {
    width: 90%;
    padding: 1em;
  }

  .frontend-logos {
    height: 100px;
    padding: 1em;
  }

  .frontend-logos-width {
    width: 250px;
    padding: 1em;
  }

  .biokey-app {
    height: 360px;
    padding: 0.3em;
  }

  .p {
    font-size: 1.1vw;
  }

  .h5 {
    font-size: 2vw;
  }

  .capstone-title {
    font-size: 1.8vw;
  }

  .cs-courses-tab {
    width: 71%;
    margin-left: auto;
    margin-right: auto;
  }

  .contact-page {
    position: fixed;

    top: 40%;
    left: 50%;
    margin-top: -50px;
    margin-left: -100px;
  }

  .contact-page-2 {
    top: 75%;
    left: 35%;
    position: fixed;
    margin-top: -50px;
    margin-left: -100px;
  }

  .color {
    background: #0f0c29; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to top,
      #24243e,
      #302b63,
      #0f0c29
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to top,
      #24243e,
      #302b63,
      #0f0c29
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

.text-center {
  text-align: center;
}

.app-background {
  background: #000000;
}

.white-background {
  background: white;
}

.card-text {
  /* display: block;
  font-size: 2em; */
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.t1 {
  justify-content: 'space-between';
}

.header-white {
  background: white; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    white,
    white
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    white,
    white
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cards {
  min-width: 550;
  margin: auto;
  padding: 4px;
  height: 780px;
}

.logo {
  width: 55px;
}
